import React from "react"
import parser from "react-html-parser"
import "../../css/harassment.css"

const Courseharassmentpolicy = ({ node }) => {
  return (
    <>
      <div className="container" id="harassmentpolicy">
        <div className="row">
          <div className="col-lg-1 col-md-12 col-sm-12"></div>
          <div className="col-lg-5 col-md-12 col-sm-12 d-flex justify-content-center">
            <img
              src={
                node.acf.policy_image.localFile.childImageSharp.fluid
                  .originalImg
              }
              alt="policy"
              className="policy"
            />
          </div>
          <div className="col-lg-5 col-md-10 col-sm-12 my-auto mx-auto">
            <div className="policysec">{parser(node.acf.policy_text)}</div>
          </div>
          <div className="col-lg-1 col-md-12 col-sm-12"></div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Courseharassmentpolicy
