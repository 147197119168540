import React from "react"
import parser from "react-html-parser"
import "../../css/harassment.css"

const Courseharassmentunconcoursetropic = ({ node }) => {
  let slug = node.acf.single_courses_slug
  let harassmentsec1, hideharessmentsec2
  if (slug == "equality_and_diversity") {
    harassmentsec1 = "harassmentsec1"
    hideharessmentsec2 = "row mainsec hideharessmentsec2"
  } else {
    hideharessmentsec2 = "row mainsec"
  }
  return (
    <>
      <div className="container" id="harassmentcoursetropic">
        <div className="heading align-center">
          <h3 className="text-center">Course Topics</h3>
        </div>
        <div className="row mainsec">
          <div className="col-lg-6 col-md-12 col-sm-12 d-lg-block d-none text-right">
            <img
              src={
                node.acf.first_sec_image.localFile.childImageSharp.fluid
                  .originalImg
              }
              alt="policy"
              className="policy"
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="coursetropic_head" id={harassmentsec1}>
              {parser(node.acf.first_sec_heading)}
            </div>
            <div className="sec1-harassment">
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_1.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-lg-3 my-auto">
                  {parser(node.acf.course_tropic_text_1)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_2.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-lg-3 my-auto">
                  {parser(node.acf.course_tropic_text_2)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_3.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-lg-3 my-auto">
                  {parser(node.acf.course_tropic_text_3)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_4.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-lg-3 my-auto ">
                  {parser(node.acf.course_tropic_text_4)}
                  {/* Types of Unconsicious bias 7 section */}
                  <div className="row harasamentsec mt-4">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image1.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text1)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image2.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text2)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image3.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text3)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image4.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text4)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image5.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text5)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image6.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text6)}
                    </div>
                  </div>

                  <div className="row harasamentsec ">
                    <div className="col-lg-2 col-md-2 p-lg-0 text-lg-center">
                      <img
                        src={
                          node.acf.unconsicious_bias_image7.localFile
                            .childImageSharp.fluid.originalImg
                        }
                      />
                    </div>
                    <div className="col-lg-8 col-md-8 col-sm-12 coursedata ">
                      {parser(node.acf.unconsicious_bias_text7)}
                    </div>
                  </div>
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 ">
                  <img
                    src={
                      node.acf.course_tropic_icon_5.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-lg-3 my-auto">
                  {parser(node.acf.course_tropic_text_5)}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Courseharassmentunconcoursetropic
