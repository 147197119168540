import React from "react"
import Layout from "../components/layout"
import Infosechighlights from "../components/infosec-single-courses/infosec-course-highlights"
import Platformhighlights from "../components/infosec-single-courses/infosec-platform-highlights"
import YouTubeapi from "../components/infosec-single-courses/info-single-embed"
import Infosecsinglehero from "../components/infosec-single-courses/infosec-single-hero"
import Infosecherodesc from "../components/infosec-single-courses/infosec-hero-bottomdesc"
import Infopricing from "../components/infosec-single-courses/infosec-single-pricing"
import Courseharassmentpolicy from "../components/harassment-discrimination-courses/harassment-policy"
import Courseharassmentcoursetropic from "../components/harassment-discrimination-courses/harassment-course-tropic"
import Relatedcourses from "../components/related_courses"
import Singlecourseform from "../components/infosec-single-courses/info-single-course-form"
import Courseharassmentunconcoursetropic from "../components/harassment-discrimination-courses/harassment-uncon-bias-topic"
import { SEO } from "gatsby-plugin-seo"

import "../css/single-course-template/infosec.css"

const harassment = ({ data }) => {
  let infosecsinglecourses = data.wordpressAcfHarassment
  let slugname = data.wordpressAcfHarassment.acf.single_courses_slug
  let seotitle = data.wordpressAcfHarassment.acf.seo_title
  let seodesc = data.wordpressAcfHarassment.acf.seo_description
  return (
    <>
      <Layout>
        <SEO
          title={seotitle}
          description={seodesc}
          pagePath={"/Harassment-discrimination-courses/" + slugname}
          schema={`{
              "@context": "http://schema.org",
              "@type": "WebPage",
              "mainEntity": {
                "@type": "Organization",
                "name": "SucceedLEARN - SucceedLEARN - Best in Class & Comprehensive eLearning ",
                "image": "https://backendev.succeedlearn.com/wp-content/uploads/2021/02/Group-238-1.png"
              }
            }`}
        />
        <Infosecsinglehero node={infosecsinglecourses} />

        <Infosecherodesc node={infosecsinglecourses} />

        <Infopricing node={infosecsinglecourses} />

        {slugname == "unconscious-bias-elearning" ? null : (
          <YouTubeapi node={infosecsinglecourses} />
        )}

        <Infosechighlights node={infosecsinglecourses} />

        <Platformhighlights node={infosecsinglecourses} />

        {slugname === "equality_and_diversity" ||
        slugname === "sexual-harassment-prevention-training" ? (
          <Courseharassmentpolicy node={infosecsinglecourses} />
        ) : null}

        {slugname === "unconscious-bias-elearning" ? (
          <Courseharassmentunconcoursetropic node={infosecsinglecourses} />
        ) : (
          <Courseharassmentcoursetropic node={infosecsinglecourses} />
        )}

        <Singlecourseform node={infosecsinglecourses} />

        <Relatedcourses node={infosecsinglecourses} />
      </Layout>
    </>
  )
}

export default harassment

export const harassqueries = graphql`
  query($id: String!) {
    wordpressAcfHarassment(id: { eq: $id }) {
      id
      wordpress_id
      acf {
        single_courses_slug
        infosec_single_course_hero_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
          yoast_head
        }
        infosec_single_course_hero_subtitle
        infosec_single_course_hero_title
        single_courses_slug
        infosec_single_course_hero_bottom_description

        infosec_single_course_hero_tablet_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_course_hero_mobile_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        infosec_single_course_pricing_button_text
        infosec_single_course_pricing_left_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_pricing_right_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        youtube_link
        infosec_single_course_highlights1_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text1

        infosec_single_course_highlights2_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text2

        infosec_single_course_highlights3_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text3

        infosec_single_course_highlights4_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text4

        infosec_single_course_highlights5_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text5

        infosec_single_course_highlights6_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        infosec_single_course_text6

        Platform_single_course_highlights1_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text1

        Platform_single_course_highlights2_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text2

        Platform_single_course_highlights3_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text3

        Platform_single_course_highlights4_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text4

        Platform_single_course_highlights5_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text5

        Platform_single_course_highlights6_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        Platform_single_course_text6

        course_tropic_text_1
        course_tropic_icon_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        course_tropic_text_2
        course_tropic_icon_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        course_tropic_text_3
        course_tropic_icon_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        course_tropic_text_4
        course_tropic_icon_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        course_tropic_text_5
        course_tropic_icon_5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        course_tropic_text_6
        course_tropic_icon_6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        course_tropic_text_7
        course_tropic_icon_7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        course_tropic_text_8
        course_tropic_icon_8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        first_sec_heading
        first_sec_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        secound_course_tropic_text_1
        secound_course_tropic_sec2_img_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_2
        secound_course_tropic_sec2_img_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_3
        secound_course_tropic_sec3_img_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_4
        secound_course_tropic_sec3_img_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_5
        secound_course_tropic_sec3_img_5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_6
        secound_course_tropic_sec3_img_6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_7
        secound_course_tropic_sec3_img_7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        secound_course_tropic_text_8
        secound_course_tropic_sec3_img_8 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        secound_sec_heading
        secound_sec_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        policy_text
        policy_image {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_1
        rcoursetitle_1
        rcourseimage_1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_2
        rcoursetitle_2
        rcourseimage_2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_3
        rcoursetitle_3
        rcourseimage_3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_4
        rcoursetitle_4
        rcourseimage_4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_5
        rcoursetitle_5
        rcourseimage_5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        rlink_6
        rcoursetitle_6
        rcourseimage_6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }

        unconsicious_bias_image1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text1
        unconsicious_bias_image2 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text2
        unconsicious_bias_image3 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text3
        unconsicious_bias_image4 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text4
        unconsicious_bias_image5 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text5
        unconsicious_bias_image6 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text6
        unconsicious_bias_image7 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        unconsicious_bias_text7
        unconsicious_bias_image1 {
          localFile {
            childImageSharp {
              fluid {
                originalImg
              }
            }
          }
        }
        seo_title
        seo_description
      }
    }
  }
`
