import React from "react"
import parser from "react-html-parser"
import "../../css/harassment.css"

const Courseharassmentcoursetropic = ({ node }) => {
  let slug = node.acf.single_courses_slug
  let harassmentsec1, hideharessmentsec2
  if (slug == "equality_and_diversity") {
    harassmentsec1 = "harassmentsec1"
    hideharessmentsec2 = "row mainsec hideharessmentsec2"
  } else {
    hideharessmentsec2 = "row mainsec"
  }
  return (
    <>
      <div className="container" id="harassmentcoursetropic">
        <div className="heading align-center">
          <h3 className="text-center">Course Topics</h3>
        </div>
        <div className="row mainsec">
          <div className="col-lg-6 col-md-12 col-sm-12 d-lg-block d-none text-right">
            <img
              src={
                node.acf.first_sec_image.localFile.childImageSharp.fluid
                  .originalImg
              }
              alt="policy"
              className="policy"
            />
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="coursetropic_head" id={harassmentsec1}>
              {parser(node.acf.first_sec_heading)}
            </div>
            <div className="sec1-harassment">
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_1.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_1)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_2.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_2)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_3.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_3)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_4.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_4)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_5.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_5)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_6.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_6)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_7.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_7)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2">
                  <img
                    src={
                      node.acf.course_tropic_icon_8.localFile.childImageSharp
                        .fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto">
                  {parser(node.acf.course_tropic_text_8)}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/*  Section 2 */}
        <div className={hideharessmentsec2} id="harassmentsec2">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="coursetropic_head">
              {parser(node.acf.secound_sec_heading)}
            </div>
            <div className="sec1-harassment">
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-xl-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec2_img_1.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_1)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec2_img_2.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_2)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_2.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_3)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_4.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_4)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_5.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_5)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_6.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_6)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_7.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_7)}
                </div>
              </div>
              <div className="row harasamentsec">
                <div className="col-lg-2 col-md-2 order-lg-2 order-1">
                  <img
                    src={
                      node.acf.secound_course_tropic_sec3_img_8.localFile
                        .childImageSharp.fluid.originalImg
                    }
                  />
                </div>
                <div className="col-lg-8 col-md-8 col-sm-12 coursedata my-auto order-xl-1 order-2">
                  {parser(node.acf.secound_course_tropic_text_8)}
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-6 col-md-12 col-sm-12 d-lg-block d-none text-left">
            <img
              src={
                node.acf.secound_sec_image.localFile.childImageSharp.fluid
                  .originalImg
              }
              alt="policy"
              className="policy"
            />
          </div>
        </div>
      </div>
      <hr />
    </>
  )
}

export default Courseharassmentcoursetropic
